import PropTypes from 'prop-types';
import * as React from 'react';

import { Footer } from '../layout/footer';

type Properties = {
    children?: React.ReactChild | React.ReactChild[];
    pullFooter?: boolean;
};

const Site = ({ children, pullFooter }: Properties): React.ReactElement => (
    <div className={'flex flex-col min-h-screen'}>
        <main className={'flex-grow'}>{children}</main>
        <Footer pull={pullFooter} />
    </div>
);

Site.propTypes = {
    children: PropTypes.node,
    pullFooter: PropTypes.bool,
};

Site.defaultProps = {
    pullFooter: false,
};

export { Site };
