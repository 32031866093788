import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import * as React from 'react';

import { Container } from '../containers/container';
import { Color, Logo, Size } from '../elements/logo';

type Properties = {
    pull: boolean;
};

const Footer = ({ pull }: Properties): React.ReactElement => (
    <footer className={classNames('relative z-100', { '-mt-40': pull })}>
        <div className={'h-40 bg-gradient-to-t via-caribbean-50 from-caribbean-200'} />
        <div className={'bg-gradient-to-b from-caribbean to-algae-400'}>
            <svg
                className={'relative -top-8 h-8 md:-top-16 md:h-16 xl2:-top-32 xl2:h-32 w-screen fill-caribbean'}
                preserveAspectRatio={'none'}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1541 105.5">
                <path d="M803 60.9C479.4-37.7 168.2-9.6 0 95.3v10.2h1541V37.7c-76.1 14-414.4 121.8-738 23.2z" />
            </svg>
            <div className={'relative -top-16 md:-top-32 xl2:-top-72'}>
                <Container>
                    <div className={'flex flex-col md:flex-row'}>
                        <div className={'flex-grow pt-4 md:pt-24 md:pr-12 order-2 md:order-none'}>
                            <span className={'inline-block font-bold text-2xl md:text-3xl lg:text-5xl text-white mb-2'}>Hallo!</span>
                            <div className={'max-w-prose text-sm md:text-base text-white'}>
                                <p>
                                    Ik ben Vicky van Fitfoodforty, en motiveer graag mensen om met kleine veranderingen in hun levensstijl
                                    gezonder te worden. Ik sport graag, vind het leuk om lekkere, praktische én gezonde recepten te
                                    verzamelen en verdiep me in de achterliggende theorie.
                                </p>
                                <p className={'mt-4'}>
                                    Vanuit Fitfoodforty geef ik{' '}
                                    <Link to={'/powerwalking'} className={'underline'}>
                                        powerwalking
                                    </Link>{' '}
                                    en ben ik{' '}
                                    <Link to={'/leefstijlcoaching'} className={'underline'}>
                                        zelfstandig leefstijlcoach
                                    </Link>
                                    . Ik ben{' '}
                                    <a href={'https://blcn.nl/leefstijlcoach/fitfoodforty'} target={'_blank'} className={'underline'}>
                                        geaccrediteerd
                                    </a>{' '}
                                    bij de landelijke beroepsvereniging BLCN.
                                </p>
                            </div>
                        </div>
                        <Logo color={Color.Lemon} size={Size.Jumbo} className={'flex-none order-1 self-end md:order-none'} />
                    </div>
                </Container>
            </div>
        </div>
        <div className={'bg-algae py-6'}>
            <Container className={'grid sm:grid-cols-2 lg:grid-cols-5 gap-5 text-sm text-white'}>
                <div className={'lg:col-span-2 xl:col-span-1'}>
                    <span className={'font-bold'}>Kleine lettertjes</span>
                    <div className={'text-md md:text-xs'}>
                        <a href={'/voorwaarden/leefstijlcoaching'} className={'underline'}>
                            Voorwaarden leefstijlcoaching
                        </a>
                        <br />
                        <a href={'/voorwaarden/powerwalking'} className={'underline'}>
                            Voorwaarden powerwalking
                        </a>
                        <br />
                        <a href={'/voorwaarden/privacy-en-cookies'} className={'underline'}>
                            Privacy- en cookieverklaring
                        </a>
                    </div>
                </div>
                <div>
                    <span className={'font-bold'}>Contact</span>
                    <div className={'text-md md:text-xs'}>
                        <a href={'mailto:vicky@fitfoodforty.nl'}>vicky@fitfoodforty.nl</a>
                        <br />
                        <a href={'https://wa.me/31641777848'} target={'_blank'} title={'App me gerust via WhatsApp!'} rel={'noreferrer'}>
                            06 4177 7848
                        </a>{' '}
                        <FontAwesomeIcon icon={['fab', 'whatsapp']} size={'1x'} className={'ml-0.5'} />
                    </div>
                </div>
                <div>
                    <span className={'font-bold'}>Leefstijlcoach</span>
                    <div className={'text-md md:text-xs'}>
                        Wat doet een leefstijlcoach? Ik{' '}
                        <Link to={'/leefstijlcoaching'} title={'Wat doet een leefstijlcoach?'} className={'underline'}>
                            leg je graag uit
                        </Link>{' '}
                        wat ik voor je kan betekenen.
                    </div>
                </div>
                <div className={'xl:col-span-2 lg:text-right'}>
                    <div className={'space-x-2'}>
                        <a
                            href={'https://facebook.com/fitfoodforty'}
                            className={'hover:text-lemon'}
                            target={'_blank'}
                            rel={'noopener'}
                            title={'Volg Fitfoodforty op Facebook'}>
                            <FontAwesomeIcon icon={['fab', 'facebook-square']} size={'2x'} />
                        </a>{' '}
                        <a
                            href={'https://instagram.com/fitfoodforty'}
                            className={'hover:text-lemon'}
                            target={'_blank'}
                            rel={'noopener'}
                            title={'Volg Fitfoodforty op Instagram'}>
                            <FontAwesomeIcon icon={['fab', 'instagram']} size={'2x'} />
                        </a>
                    </div>
                    <p className={'text-xs mt-2'}>
                        Website door{' '}
                        <a href={'https://hivecollective.nl'} className={'underline'} rel={'noopener'}>
                            Hive Collective
                        </a>
                    </p>
                </div>
            </Container>
        </div>
    </footer>
);

Footer.propTypes = {
    pull: PropTypes.bool,
};

Footer.defaultProps = {
    pull: false,
};

export { Footer };
