import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import * as React from 'react';
import { Helmet } from 'react-helmet';

type Properties = {
    title: string;
    description: string;
    openGraphImage?: string;
    meta: string;
};

const Seo = ({ title, description, openGraphImage, meta }: Properties): React.ReactElement => {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        longTitle
                        description
                        openGraph {
                            defaultImage
                        }
                        siteUrl
                    }
                }
            }
        `
    );

    const renderDescription = description || site.siteMetadata.description;
    const renderOpenGraphImage = openGraphImage || site.siteMetadata.openGraph.defaultImage;

    return (
        <Helmet
            htmlAttributes={{
                lang: 'nl',
            }}
            title={title}
            titleTemplate={`%s • ${site.siteMetadata.longTitle}`}
            meta={[
                ...[
                    {
                        name: 'description',
                        content: renderDescription,
                    },
                    {
                        property: 'og:title',
                        content: title,
                    },
                    {
                        property: 'og:description',
                        content: renderDescription,
                    },
                    {
                        property: 'og:type',
                        content: 'website',
                    },
                    {
                        property: 'og:image',
                        content: site.siteMetadata.siteUrl + renderOpenGraphImage,
                    },
                    {
                        property: 'og:image:width',
                        content: 1200,
                    },
                    {
                        property: 'og:image:height',
                        content: 630,
                    },
                ],
                ...meta,
            ]}
        />
    );
};

Seo.defaultProps = {
    description: '',
    openGraphImage: null,

    meta: [],
};

Seo.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    openGraphImage: PropTypes.string,

    meta: PropTypes.arrayOf(PropTypes.object),
};

export { Seo };
