import classNames from 'classnames';
import PropTypes from 'prop-types';
import * as React from 'react';

type Properties = {
    children?: React.ReactChild | React.ReactChild[];
    className: string;
};

const Container = ({ children, className }: Properties): React.ReactElement => {
    return <div className={classNames('container max-w-screen-xl', className)}>{children}</div>;
};

Container.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
};

Container.defaultProps = {
    className: '',
};

export { Container };
